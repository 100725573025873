import styled from 'styled-components';
import tw from 'tailwind.macro';

export const AppStoreImages = styled.div`
  ${tw`mx-auto flex items-center justify-center w-full`};
`;

export const Image = styled.figure`
  ${tw`mx-auto sm:w-1/2 w-full`};
`;

export const AppStoreImage = styled.figure`
  ${tw`mx-auto px-2 pb-4`};
`;