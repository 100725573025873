import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Banner = styled.section`
  background-color: #FFFFB0;
  ${tw`border-b border-indigo-100`};
`;


export const CallToAction = styled.p`
  ${tw`font-bold text-center my-0 mt-8`};
`;

export const CallToActionSub = styled.p`
  ${tw`font-bold text-center mb-4 mt-0`};
`;

export const BannerTitle = styled.h2`
  ${tw`mb-4 sm:text-3xl text-4xl font-bold sm:text-left text-center`};
`;

export const BannerText = styled.div`
  ${tw`sm:w-1/2 w-full mx-auto`};
`;

export const Image = styled.figure`
  ${tw`mx-auto sm:w-1/2 w-full`};
`;
