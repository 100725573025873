import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import FindSurveyorHero from 'components/FindSurveyor/Hero';
import FindSurveyorBody from 'components/FindSurveyor/Body';

const FindASurveyor: React.FC = () => {
  return (
    <Layout>
      <SEO title="Find a Surveyor/Snagger in Ireland" />
      <FindSurveyorHero />
      <FindSurveyorBody />
    </Layout>
  );
};

export default FindASurveyor;
