import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Banner from 'components/ui/Banner';

import { SectionTitle } from 'helpers/definitions';

interface FindAgentHeroData extends SectionTitle {
  content: string;
  callToAction: string;
  callToActionSubtitle: string;
  linkTo: string;
  linkText: string;
  imageFile: string;
  dataCategory: string;
}

const FindSurveyorHero: React.FC = () => {
  const { markdownRemark, file } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "find surveyor header" } }) {
        html
        frontmatter {
          title
          subtitle
          content
          callToAction
          callToActionSubtitle
          linkTo
          linkText
        }
      }
      file(relativePath: { eq: "feature_surveyor.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `,);

  const heroData: FindAgentHeroData = markdownRemark.frontmatter;
  const img = file.childImageSharp.fluid;

  return (
    <Banner
      title={heroData.title}
      subtitle={heroData.subtitle}
      content={markdownRemark.html}
      callToAction={heroData.callToAction}
      linkTo={heroData.linkTo}
      linkText={heroData.linkText}
      appImage={img}
    />
  );
};

export default FindSurveyorHero;
