import React from 'react';
import * as Styled from './styles';
import Img, { FixedObject, FluidObject } from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { useMixpanel } from 'gatsby-plugin-mixpanel'

interface Props {
  campaign: string;
}

const AppStoreImageLinks: React.FC = () => {
    const { playStoreImage, iosStoreImage } = useStaticQuery(graphql`
      query {
        playStoreImage: file(relativePath: { eq: "playstore.png" }) {
          childImageSharp {
            fixed(width: 160) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        iosStoreImage: file(relativePath: { eq: "iosstore.png" }) {
          childImageSharp {
            fixed(width: 160) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `);

  const mixpanel = useMixpanel();

  return (
    <Styled.AppStoreImages>
      <a href='https://play.google.com/store/apps/details?id=ie.cuhomehub.cuapp' onClick={() => mixpanel.track('playLinkClick')}>
        <Styled.AppStoreImage><Img fixed={playStoreImage.childImageSharp.fixed} alt="Link to the Google Play Store" /></Styled.AppStoreImage>
      </a>
      <a href='https://apps.apple.com/us/app/cu-home-hub/id6737483070' onClick={() => mixpanel.track('iosLinkClick')}>
        <Styled.AppStoreImage><Img fixed={iosStoreImage.childImageSharp.fixed} alt="Link to the iOS Store" /></Styled.AppStoreImage>
      </a>
    </Styled.AppStoreImages>
  );
};

export default AppStoreImageLinks;
